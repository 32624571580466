<template>
    <div class="AwardCardCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select v-model="form.deptGroupCode" :loading="loadingDeptGroupFlag" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="会员卡类型">
                            <el-input v-model="form.cardTypeName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="$store.state.session.name" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建时间">
                            <el-date-picker v-model="form.createDate" type="date" value-format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="save" size="small">保存</el-button>
            <el-button type="primary" @click="handleAdd" size="small">添加</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="会员卡号" width="240" prop="cardPrintNos">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.cardPrintNos" size="small" />
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="deleteRow(scope.$index, tableData, scope.row)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'AwardCardCreate',
    data() {
        return {
            form: {
                cardTypeName: '符号卡',
                deptGroupCode: '',
                remark: '',
                createDate: new Date(),
                creator: this.$store.state.session.name,
                page: 1,
                limit: 100,
            },
            meta: {
                groups: [],
            },
            loadingDeptGroupFlag: true,
            url: {
                queryGroups: '/system/deptGroup/list',
                save: '/member/awardCard/createExtend',
            },
            areaData: {
                level: 1,
            },
            tableData: [],
        };
    },
    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            this.loadingDeptGroupFlag = false;
        });
    },
    methods: {
        save() {
            const _this = this;
            if (!_this.tableData.length) {
                return;
            }
            if (_this.form.deptGroupCode == '') {
                _this.$message.error('请选择机构组');
                return;
            }
            if (this.tableData.length <= 0) {
                _this.$message.error('请添加数据');
                return;
            }
            if (this.tableData.filter((r) => !r.cardPrintNos).length) {
                _this.$message.error('请填写所有的会员卡号');
                return;
            }
            const _params = {
                memberCards: _this.tableData.map((d) => d.cardPrintNos),
                chipCards: _this.tableData.map((d) => ' '),
                deptGroupCode: _this.form.deptGroupCode,
                cardType: 'symbol',
            };
            UrlUtils.PostRemote(this, this.url.save, _params);
        },
        deleteRow(index, rows, row) {
            rows.splice(index, 1);
        },
        handleAdd() {
            const _this = this;
            if (_this.form.deptGroupCode == '') {
                _this.$message.error('请选择机构组');
                return;
            }
            _this.tableData.push({});
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
